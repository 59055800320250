<template>
  <div class="table_wrapper">
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1" editable>
          Category Info
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 2" step="2" editable>
          SEO
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 3" step="3" editable>
          Photos
        </v-stepper-step>
        <v-divider></v-divider>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="p-3">
            <v-row align-content="center" class="mb-5">
              <v-icon aria-hidden="false" color="red">
                mdi-alert-octagram
              </v-icon>
              <p class="m-0 font-weight-medium">
                Please enter category title and description in all languages.
              </p>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h5 class="red--text">Default</h5>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="form.title" label="Title"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="form.slug" label="Slug"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="form.description"
                  label="Description"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row v-for="(a, index) in form.translations" :key="index">
              <v-col cols="12">
                <h5 class="red--text">{{ a.name }}</h5>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="a.title" label="Title"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="a.description"
                  label="Description"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="p-3">
            <v-row align="center">
              <v-col cols="6">
                <v-text-field
                  v-model="form.meta_title"
                  label="Meta Title"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-combobox
                  v-if="!dialog"
                  v-model="form.meta_keywords"
                  :items="form.meta_keywords"
                  chips
                  hide-selected
                  label="Meta Keywords"
                  multiple
                  prepend-icon="mdi-filter-variant"
                  solo
                >
                  <template
                    v-slot:selection="{ attrs, item, select, selected }"
                  >
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      @click="select"
                      @click:close="removeKeyword(item)"
                    >
                      <strong>{{ item }}</strong>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="form.meta_description"
                  label="Meta Descriptipn"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card class="p-3">
            <v-row align="center">
              <v-col cols="4">
                <h5>ICON - <small>64x64 px</small></h5>
                <v-file-input
                  v-model="file1"
                  placeholder="Upload your documents"
                  label="File input"
                  outlined
                  dense
                  v-on:change="filePreview(1)"
                  prepend-icon="mdi-paperclip"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
              <v-col cols="2">
                <v-btn
                  :loading="loader1"
                  :disabled="file1 == null || loader1"
                  color="primary"
                  class="ma-2 white--text"
                  @click="fileUpload(1), (loader1 = true)"
                >
                  Upload
                  <v-icon right dark>
                    mdi-cloud-upload
                  </v-icon>
                </v-btn>
              </v-col>

              <v-col cols="2">
                <h5>PREVIEW</h5>
                <v-img
                  lazy-src="https://via.placeholder.com/64x64"
                  max-height="64"
                  max-width="64"
                  :src="file_1_preview"
                  @click="
                    (dialogImage = true), (dialogImageUrl = file_1_preview)
                  "
                ></v-img>
              </v-col>

              <v-col cols="2">
                <h5>CURRENT</h5>
                <v-img
                  lazy-src="https://via.placeholder.com/64x64"
                  max-height="64"
                  max-width="64"
                  :src="form.icon_1"
                  @click="(dialogImage = true), (dialogImageUrl = form.icon_1)"
                ></v-img>
              </v-col>
              <v-col cols="2">
                <h5>REMOVE</h5>
                <v-btn
                  color="red"
                  icon
                  raised
                  class="ma-2 white--text"
                  v-on:click="form.icon_1 = 'https://via.placeholder.com/64x64'"
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-divider class="my-5 pb-5"></v-divider>
            <v-row align="center">
              <v-col cols="4">
                <h5>LEFT BANNER - <small>543-1355 px</small></h5>
                <v-file-input
                  v-model="file2"
                  placeholder="Upload your documents"
                  label="File input"
                  outlined
                  dense
                  v-on:change="filePreview(2)"
                  prepend-icon="mdi-paperclip"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
              <v-col cols="2">
                <v-btn
                  :loading="loader2"
                  :disabled="file2 == null || loader2"
                  color="primary"
                  class="ma-2 white--text"
                  @click="fileUpload(2), (loader2 = true)"
                >
                  Upload
                  <v-icon right dark>
                    mdi-cloud-upload
                  </v-icon>
                </v-btn>
              </v-col>

              <v-col cols="2">
                <h5>PREVIEW</h5>
                <v-img
                  lazy-src="https://via.placeholder.com/543x1355"
                  max-height="200"
                  max-width="200"
                  :src="file_2_preview"
                  @click="
                    (dialogImage = true), (dialogImageUrl = file_2_preview)
                  "
                ></v-img>
              </v-col>
              <v-col cols="2">
                <h5>CURRENT</h5>
                <v-img
                  lazy-src="https://via.placeholder.com/543x1355"
                  max-height="200"
                  max-width="200"
                  :src="form.left_banner"
                  @click="
                    (dialogImage = true), (dialogImageUrl = form.left_banner)
                  "
                ></v-img>
              </v-col>
              <v-col cols="2">
                <h5>REMOVE</h5>
                <v-btn
                  color="red"
                  icon
                  raised
                  class="ma-2 white--text"
                  v-on:click="
                    form.left_banner = 'https://via.placeholder.com/543x1355'
                  "
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-divider class="my-5 pb-5"></v-divider>
            <v-row align="center">
              <v-col cols="4">
                <h5>TOP BANNER - <small>1768 x 278 px</small></h5>
                <v-file-input
                  v-model="file3"
                  placeholder="Upload your documents"
                  label="File input"
                  outlined
                  dense
                  v-on:change="filePreview(3)"
                  prepend-icon="mdi-paperclip"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
              <v-col cols="2">
                <v-btn
                  :loading="loader3"
                  :disabled="file3 == null || loader3"
                  color="primary"
                  class="ma-2 white--text"
                  @click="fileUpload(3), (loader3 = true)"
                >
                  Upload
                  <v-icon right dark>
                    mdi-cloud-upload
                  </v-icon>
                </v-btn>
              </v-col>

              <v-col cols="2">
                <h5>PREVIEW</h5>
                <v-img
                  lazy-src="https://via.placeholder.com/1768x278"
                  max-height="200"
                  max-width="200"
                  :src="file_3_preview"
                  @click="
                    (dialogImage = true), (dialogImageUrl = file_3_preview)
                  "
                ></v-img>
              </v-col>

              <v-col cols="2">
                <h5>CURRENT</h5>
                <v-img
                  lazy-src="https://via.placeholder.com/1768x278"
                  max-height="200"
                  max-width="200"
                  :src="form.top_banner"
                  @click="
                    (dialogImage = true), (dialogImageUrl = form.top_banner)
                  "
                ></v-img>
              </v-col>
              <v-col cols="2">
                <h5>REMOVE</h5>
                <v-btn
                  color="red"
                  icon
                  raised
                  class="ma-2 white--text"
                  v-on:click="
                    form.top_banner = 'https://via.placeholder.com/1768x278'
                  "
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-divider class="my-5 pb-5"></v-divider>
            <v-row align="center">
              <v-col cols="4">
                <h5>BANNERS - <small>1095 x 150 px</small></h5>
                <v-file-input
                  v-model="file4"
                  placeholder="Upload your documents"
                  label="File input"
                  multiple
                  outlined
                  dense
                  prepend-icon="mdi-paperclip"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
              <v-col cols="2">
                <v-btn
                  :loading="loader4"
                  :disabled="file4 == null || loader4"
                  color="primary"
                  class="ma-2 white--text"
                  @click="fileUpload(4), (loader4 = true)"
                >
                  Upload
                  <v-icon right dark>
                    mdi-cloud-upload
                  </v-icon>
                </v-btn>
              </v-col>

              <v-col cols="6">
                <h5>CURRENT</h5>

                <v-carousel height="150">
                  <v-carousel-item
                    v-for="(item, i) in form.settings.slides"
                    :key="i"
                    :src="item"
                    @click="(dialogImage = true), (dialogImageUrl = item)"
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                  ></v-carousel-item>
                </v-carousel>
              </v-col>
            </v-row>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-card class="mt-5">
      <v-card-actions>
        <v-btn text @click="e1 = 1">
          Prev
        </v-btn>
        <v-btn
          class="ml-3"
          dark
          :disabled="dialog"
          :loading="dialog"
          v-on:click="getData($route.params.id)"
        >
          Reload
        </v-btn>
        <v-btn
          class="ml-3"
          color="green"
          dark
          :disabled="dialog"
          :loading="dialog"
          v-on:click="saveAllData"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialogImage" width="600">
      <v-card>
        <v-img :src="dialogImageUrl"></v-img>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="p-3">
          Loading.. Please wait.
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "CategoriesEdit",
  data() {
    return {
      e1: 1,
      dialogImage: false,
      dialogImageUrl: null,
      loading: true,

      loader1: false,
      loader2: false,
      loader3: false,
      loader4: false,
      dialog: true,
      langs: [],
      file1: null,
      file2: null,
      file3: null,
      file4: null,

      file_1_preview: "https://via.placeholder.com/64x64",
      file_2_preview: "https://via.placeholder.com/543x1355",
      file_3_preview: "https://via.placeholder.com/1768x278",
      form: {
        id: null,
        category_page_top_banner: "",
        description: "",
        icon_1: "",
        icon_2: "",
        icon_3: null,
        left_banner: "",
        top_banner: "",
        meta_description: "",
        meta_keywords: "",
        meta_title: "",
        parent_id: null,
        settings: {
          featured_suppliers_bg: null,
          new_products_bg: null,
          new_products_count: null,
          slides: [],
          suppliers: [],
        },
        slug: "",
        title: "",
        translations: {},
      },
      token: localStorage.getItem("authToken"),
    };
  },
  methods: {
    filePreview: function(type) {
      let preview = "";
      switch (type) {
        case 1:
          preview = this.file1 ? this.file1 : null;
          if (preview == null) {
            preview = "";
            this.file_1_preview = "https://via.placeholder.com/64x64";
            return false;
          }
          this.file_1_preview = URL.createObjectURL(preview);
          break;
        case 2:
          preview = this.file2 ? this.file2 : null;
          if (preview == null) {
            preview = "";
            this.file_2_preview = "https://via.placeholder.com/543x1355";
            return false;
          }
          this.file_2_preview = URL.createObjectURL(preview);
          break;
        case 3:
          preview = this.file3 ? this.file3 : null;
          if (preview == null) {
            preview = "";
            this.file_3_preview = "https://via.placeholder.com/1768x278";
            return false;
          }
          this.file_3_preview = URL.createObjectURL(preview);
          break;
      }
    },
    fileUpload(type) {
      let filesData;
      switch (type) {
        case 1:
          filesData = this.file1 ? this.file1 : null;
          this.uploader(filesData)
            .then((res) => {
              this.form.icon_1 = res.data.file_paths[0];
              this.loader1 = false;
              this.file1 = null;
              this.file_1_preview = "https://via.placeholder.com/64x64";
            })
            .catch((err) => {
              console.log(err);
            });
          break;
        case 2:
          filesData = this.file2 ? this.file2 : null;
          this.uploader(filesData)
            .then((res) => {
              this.form.left_banner = res.data.file_paths[0];
              this.loader2 = false;
              this.file2 = null;
              this.file_2_preview = "https://via.placeholder.com/543x1355";
            })
            .catch((err) => {
              console.log(err);
            });
          break;
        case 3:
          filesData = this.file3 ? this.file3 : null;
          this.uploader(filesData)
            .then((res) => {
              this.form.top_banner = res.data.file_paths[0];
              this.loader3 = false;
              this.file3 = null;
              this.file_3_preview = "https://via.placeholder.com/1768x278";
            })
            .catch((err) => {
              console.log(err);
            });
          break;
        case 4:
          filesData = this.file4 ? this.file4 : null;

          this.uploader(filesData)
            .then((res) => {
              this.form.settings.slides = res.data.file_paths;
              this.loader4 = false;
              this.file4 = null;
            })
            .catch((err) => {
              console.log(err);
            });
          break;
      }
    },
    async uploader(data) {
      try {
        let formDataClass = new FormData();
        if (data.length) {
          for (let i = 0; i < data.length; i++) {
            formDataClass.append("file[]", data[i]);
          }
        } else {
          formDataClass.append("file", data);
        }
        const response = await axios.post(
          process.env.VUE_APP_API_URL + "upload",
          formDataClass,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        return response;
      } catch (error) {
        return error;
      }
    },
    removeKeyword: function(item) {
      const index = this.form.meta_keywords.findIndex((a) => a == item);
      this.form.meta_keywords.splice(index, 1);
    },
    saveAllData: function() {
      this.dialog = true;
      this.form.id = Number(this.$route.params.id);
      // buradayım.
      return false;
      axios
        .put(
          process.env.VUE_APP_API_URL +
            "category/" +
            this.$route.params.id +
            "?api_token=" +
            this.token,
          this.form
        )
        .then((res) => {
          this.dialog = false;
          this.$router.push({
            name: "Categories",
          });
        })
        .catch((err) => {
          console.log(err);
          this.dialog = false;
        });
    },
    getLang: function() {
      let url =
        process.env.VUE_APP_API_URL +
        "language/filter?page=1&per_page=1000&api_token=" +
        this.token;
      axios
        .post(url)
        .then((res) => {
          this.langs = res.data.data.data;

          let mlangs = {};

          this.langs.forEach((item) => {
            mlangs[item.id] = {
              name: item.name,
              title: "",
              description: "",
            };
          });

          this.form.translations = mlangs;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getData: function(id) {
      this.dialog = true;
      let url =
        "https://gowawe.com/api/admin/category/" +
        id +
        "?api_token=" +
        this.token;
      axios
        .get(url)
        .then((res) => {
          this.dialog = false;
          const resp = res.data;
          this.form.id = resp.id;
          this.form.title = resp.title;
          this.form.slug = resp.slug;
          this.form.description = resp.description;
          this.form.meta_title = resp.meta_title;
          this.form.meta_description = resp.meta_description;
          this.form.settings = resp.settings;
          this.form.icon_1 = resp.icon_1 ?? "https://via.placeholder.com/64x64";
          this.form.icon_2 =
            resp.icon_2 ?? "https://via.placeholder.com/100x100";
          this.form.left_banner =
            resp.left_banner ?? "https://via.placeholder.com/543x1355";
          this.form.top_banner =
            resp.top_banner ?? "https://via.placeholder.com/1768x278";
          this.form.meta_keywords =
            resp.meta_keywords?.split(",")?.map((a) => {
              return a.trim();
            }) ?? "";

          this.getLang();
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.getData(this.$route.params.id);
    //this.getCategories();
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>

<style scoped>
.v-image {
  cursor: pointer;
}
</style>
